import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layouts/default'


const GoogleCalendar = () => (
	<Layout>

		<div className="container-fluid ">
			<h1 className="text-white text-center mt-5 mb-5" style={{letterSpacing: '0.06em', fontWeight: 200 }}>Google Calendar Integration</h1>
			<div className="container">

				<div className="row">
					<div className="col-md-3 mb-4  card card-feature" style={{minHeight: '100vh'}}>
						<div className="py-2 sticky-top">
							<h6 class="menu-title">Integrations</h6>
							<div className="nav flex-column">
								<a href="/integrations/#intro" className="nav-link activex">Introduction</a>
								<a href="/integrations/#integrations" className="nav-link">Integrations</a>
							</div>
						</div>
					</div>
					<div className="col-md-9 mb-4">
						<div className="card card-feature">
							<div className="help-body" style={{padding: 40}}>
								<p className="pt-2" style={{fontWeight: 100, fontSize: 18}}>
								Getting your Reldesk Calendar feed into Google's Calendar is relatively straightforward, you just need to copy the calendar feed link and paste it into Google Calendar under the "Other Calendars" section.
								</p>

								<h2 id="integrations">Add to Google Calendar, step-by-step</h2>
								<p>
									First you'll need to copy the calendar feed link from Reldesk. Just go to the <strong>Appointments</strong> section and
									click on the <strong>Feed</strong> button to get the feed link.
								</p>
								<img src="/google-calendar/reldesk-calendar-feed.png" className="mt-3 mb-4 doc-image img-fluid" alt=""/>

								<p>
									Now login to GSuite and load up your Google Calendar
								</p>

								<img src="/google-calendar/google-calendar-step1.png" className="mt-3 mb-4 doc-image img-fluid" alt=""/>
								<p>
									In the bottom right-hand corner, click the <strong>+</strong> next to Other Calendars and select <strong>From URL</strong> from the popup menu.
								</p>
	
								<div className="a">
									<img src="/google-calendar/google-calendar-step2.png" className="mr-3 mt-3 mb-4 doc-image img-fluid" alt="" style={{maxWidth: 300}}/>
									<img src="/google-calendar/google-calendar-step3.png" className="mr-3 mt-3 mb-4 doc-image img-fluid" alt="" style={{maxWidth: 300}}/>
								</div>

								<p>
									Then just paste in the Calendar Address you copied in the first step and click on <strong>Add calendar</strong>.
								</p>

								<img src="/google-calendar/google-calendar-step4.png" className="mt-3 mb-4 doc-image img-fluid" alt=""/>
								
								<p>
									Once you have it in there you can change the name to something more descriptive. Now your Reldesk calendar will
									sync into your Google Calendars.
								</p>

								<img src="/google-calendar/google-calendar-step5.png" className="mt-3 mb-4 doc-image img-fluid" alt=""/>





							</div>
						</div>
					</div>
				</div>
		    </div>
		</div>


	</Layout>
)

export default GoogleCalendar
